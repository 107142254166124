import { useTrans } from 'src/ui/hooks/useTrans'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { Button, Divider, Link, Text } from 'src/ui/components'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Translate } from 'src/ui/i18n/Translate'
import styles from './ErrorNotFound.module.scss'
import { useCallCenter } from 'src/ui/hooks/useCallCenter'
import { Image } from 'src/ui/components/atoms/Image'
import { Media } from 'src/ui/styles/objects/Media'
import { decode } from 'querystring'
import { container } from 'src/core/Shared/_di'
import { getAppliedCoupons } from 'src/core/Cart/domain/Cart.model'
import { useCart } from 'src/ui/contexts/CartContext'
import { SimpleHeader } from 'src/ui/views/_layouts/SimpleHeader'

export const ErrorNotFound = () => {
  const { trans } = useTrans(['common'])
  const { navigate, asPath } = useApplicationRouter()
  const { cart } = useCart()
  const { callCenterCountryCode, callCenterPhone, callCenterFreePhone } =
    useCallCenter()

  const queryParams = decode(asPath.split('?')[1])
  const getImgUrl = () =>
    `${container.resolve('envManager').getBaseImagesUrl()}default-error-page`

  const handleClick = async () => {
    await navigate.toAvailabilityOnNotFoundError({
      ...queryParams,
      ...getAppliedCoupons(cart),
    })
  }

  return (
    <>
      <SimpleHeader />
      <section className={styles.imageContainer}>
        <Image
          alt="error"
          src={getImgUrl()}
          fill
          sizes={`(min-width: 0px) 100vw`}
          className={styles.image}
          quality={75}
        />
      </section>
      <section className={styles.textContainer}>
        <Text fontStyle="2xl-700" color="dark" as="h1">
          {trans('not-found-error_title')}
        </Text>
        <Media mobile tablet>
          <Divider className={styles.divider} dir="horizontal" />
        </Media>
        <Flex direction="column" gap="m" className="mt-m">
          <Text fontStyle="m-300" color="dark">
            {trans('not-found-error_description')}
          </Text>

          <Translate
            i18nKey="not-found-error_call-us"
            values={{
              free: `${callCenterPhone}${callCenterFreePhone}`,
              moreOptions: trans('not-found-error_other-options'),
            }}
            components={{
              regular: <Text as="p" fontStyle="m-300" />,
              bold: (
                <Link
                  data-data-phone={callCenterCountryCode}
                  link={`tel:${callCenterPhone}`}
                  variant="secondary"
                >
                  {`${callCenterPhone}${callCenterFreePhone}`}
                </Link>
              ),
              options: (
                <Text fontStyle="m-300">
                  {trans('not-found-error_other-options')}
                </Text>
              ),
            }}
          />
          <div className="w-full">
            <Button
              type="submit"
              size={{
                mobile: 'fullWidth',
                laptop: 'large',
              }}
              onClick={handleClick}
            >
              {trans('not-found-error_go-home')}
            </Button>
          </div>
        </Flex>
      </section>
    </>
  )
}
