import { useEffect, useState } from 'react'
import { container } from 'src/core/Shared/_di'
import { ErrorNotFound } from 'src/ui/views/ErrorNotFound'

export default function Custom404() {
  const [previousRoute, setPreviousRoute] = useState<string | null>(null)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      try {
        const route =
          container.resolve('sessionStorageClient').get('previousRoute') ??
          'No previous route'
        setPreviousRoute(
          typeof route === 'string' ? route : 'No previous route',
        )
      } catch (error) {
        console.error('Error accessing sessionStorageClient:', error)
      }
    }
  }, [])

  const logger = container.resolve('logger')
  useEffect(() => {
    if (previousRoute) {
      logger.error(new Error(`Error 404 on path: ${previousRoute}`))
    } else {
      logger.error(
        new Error('Error 404 on path: Server-side request or unknown route'),
      )
    }
  }, [previousRoute])

  return <ErrorNotFound />
}
